import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/theme.scss"
import Logo from "../images/logo.kdc-small.svg"
import Callout from "../components/callout";
import Layout from "../layouts/layout";

const About = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <SEO metaTitle={`About - ${global.title}`} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-wrapper--small o-content o-container">
                <div className="o-content-container">
                    <h1>
                        <img className="c-logo c-logo--small" src={Logo} alt={global.title}></img>
                    </h1>
                    <div className="c-video">
                        <iframe title="carousel-video" className="c-video__frame u-img-border" src={`https://www.youtube.com/embed/${data.contentfulAboutPage.videoUrl.substring(data.contentfulAboutPage.videoUrl.lastIndexOf('/') + 1)}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="u-text-large">
                        {data.contentfulAboutPage.primaryContent.primaryContent}
                    </div>
                </div>
            </div>
            <div className="o-content-container">
                {data.contentfulAboutPage.primaryCards.map((callout, index) => <Callout key={index} callout={callout} />)}
            </div>
        </Layout>
    );
};

export default About

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Kneading Dough Canada"}) {
        author
        description
        title
        tagline
        socialPostsTitle
        articleCtaText
        shareImage {
            file {
                url
            }
        }
    }
    contentfulAboutPage(website: {title: {eq: "Kneading Dough Canada"}}) {
        videoUrl
        primaryContent {
            primaryContent
        }
        primaryCards {        
            buttonText
            url
            title
            disclaimer {
                raw
            }
            subtitle
        }
    }
}
`;
